import superagent, {SuperAgentRequest} from "superagent";
class CoreRequest {
    private static coreURL = "https://video23d-api.zibra.ai/video-to-3d" // "https://generation.zibra.ai/video-to-3d"
    public post(route: string = "/"): SuperAgentRequest {
        return superagent.post(CoreRequest.coreURL + route);
    }

    public get(route: string = "/"): SuperAgentRequest {
        return superagent.get(CoreRequest.coreURL + route)
    }

    public delete(route: string = "/"): SuperAgentRequest {
        return superagent.delete(CoreRequest.coreURL + route)
    }

    public patch(route: string = "/"): SuperAgentRequest {
        return superagent.patch(CoreRequest.coreURL + route)
    }
}

export const coreRequest: CoreRequest = new CoreRequest();
