import React from "react";
import generateStyles from "./styles";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useTheme} from "@mui/material";
import Dropzone, {DropEvent, FileRejection} from "react-dropzone";
import LinearProgressWithLabel from "../LinearProgressWithLabel/LinearProgressWithLabel";
import useLocalStorage from "../../hooks/useLocalStorage";
import JobItem from "../../interfaces/JobItem";
import decodeJobsCache from "../../core/decodeJobsCache";
import encodeJobsCache from "../../core/encodeJobsCache";
import useAuth from "../../hooks/useAuth";
import {coreRequest} from "../../core/coreRequest";

export interface UploadVideoDialogProps {
    isOpen: boolean,
    setIsOpen: (value: boolean) => void,
    onSuccessfullyLoad?: (file: File) => void;
    onErrorLoad?: () => void;
}

const UploadVideoDialog = React.forwardRef((props: UploadVideoDialogProps, ref) => {
    const {
        isOpen,
        setIsOpen,
        onSuccessfullyLoad,
        onErrorLoad,
    } = props;

    const lStorage = useLocalStorage();
    const {email} = useAuth();

    const theme = useTheme();
    const styles = generateStyles(theme, props);

    const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
    const [uploadProgress, setUploadProgress] = React.useState<number | null>(null);
    const [errorInfo, setErrorInfo] = React.useState<string | null>(null);
    const handleClose = (): void => {
        setIsOpen(false);
    }

    const handleFileSelect = (accepted: File[], rejected: FileRejection[], event: DropEvent): void => {
        if (accepted.length) {
            setSelectedFile(accepted[0]);
        } else {
            setSelectedFile(null);
        }
        setUploadProgress(null);
    }

    React.useEffect(() => {
        if (selectedFile && email) {
            setUploadProgress(0);
            coreRequest
                .post("/model")
                .set("accept", "json")
                .field("email", email)
                .attach("file", selectedFile as any)
                .on("progress", event => {
                    setUploadProgress(prev => event.percent === undefined ? prev : event.percent);
                })
                .then(response => {
                    let jobs: JobItem[] = [];
                    if (lStorage.items.videoProcessingJobs) {
                        jobs = decodeJobsCache(lStorage.items.videoProcessingJobs);
                    }
                    jobs.push({
                        uid: `${response.body.id}`,
                        email: email,
                        filename: selectedFile.name,
                    });
                    lStorage.setItem("videoProcessingJobs", encodeJobsCache(jobs));
                    setUploadProgress(null);
                    setSelectedFile(null);
                    setIsOpen(false);
                    onSuccessfullyLoad && onSuccessfullyLoad(selectedFile);
                })
                .catch(error => {
                    setUploadProgress(null);
                    setErrorInfo(error.text || "Unrecognized error");
                    setSelectedFile(null);
                    onErrorLoad && onErrorLoad();
                });
        }
    }, [selectedFile]);

    return (
        <Dialog
            fullWidth
            open={isOpen}
            onClose={handleClose}
        >
            <DialogTitle>Upload video</DialogTitle>
            <DialogContent>
                {!selectedFile &&
                    <Dropzone onDrop={handleFileSelect} multiple={false}>
                        {({getRootProps, getInputProps}) => (
                            <section>
                                <div {...getRootProps()} style={{...styles.testclass, textAlign: "center"}}>
                                    <input {...getInputProps()} />
                                    <Typography component="span">
                                        Upload video  only in .mp4, .mkv, .mov, .avi  file formats. Drag and drop your
                                        file here or press + to choose a file from your local storage.
                                        Maximum file size is 600Mb.
                                    </Typography>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                }

                {uploadProgress !== null &&
                    <React.Fragment>
                        <Typography>Wait till the video is fully uploaded...</Typography>
                        <LinearProgressWithLabel value={uploadProgress}/>
                    </React.Fragment>
                }
                {errorInfo !== null &&
                    <Box>
                        <Typography color={"error"}> {errorInfo} </Typography>
                    </Box>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color={"error"} className="zibraFont">Cancel</Button>
            </DialogActions>
        </Dialog>
    );
});

export default UploadVideoDialog;
