import React from "react";
import {Box, LinearProgress, LinearProgressProps, Typography, useTheme} from "@mui/material";
import generateStyles from "./styles";

export interface LinearProgressWithLabelProps extends LinearProgressProps {
    value: number;
}

const LinearProgressWithLabel = React.forwardRef((props: LinearProgressWithLabelProps, ref) => {
    const theme = useTheme();
    const styles = generateStyles(theme, props);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
});

export default LinearProgressWithLabel;
