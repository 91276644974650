import React from "react";
import {List, ListItem, ListItemAvatar, ListItemText, useTheme} from "@mui/material";
import VideocamIcon from "@mui/icons-material/Videocam";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

const UserGuideChecklist = React.forwardRef(() => {
    const theme = useTheme();
    return (
        <List>
            <ListItem dense>
                <ListItemAvatar><VideocamIcon/></ListItemAvatar>
                <ListItemText
                    primaryTypographyProps={{style: {fontWeight: "bold"}}}
                    primary={"Record a video of an object"}
                    secondary={"Follow our tutorial to get the best 3D model quality. The background should include different objects (be non-monotonous)."}
                />
            </ListItem>
            <ListItem dense>
                <ListItemAvatar><CloudUploadIcon/></ListItemAvatar>
                <ListItemText
                    primaryTypographyProps={{style: {fontWeight: "bold"}}}
                    primary={"Upload video to our server"}
                    secondary={"Choose one of the following file formats: .mp4, .mkv, .mov, .avi. Video titles should not include special characters or spaces"}
                />
            </ListItem>
            <ListItem dense>
                <ListItemAvatar><HourglassTopIcon/></ListItemAvatar>
                <ListItemText
                    primaryTypographyProps={{style: {fontWeight: "bold"}}}
                    primary={"Wait for ZibraAI’s tech to process the data and build a 3D model"}
                    secondary={"It may take up to 1 hour"}
                />
            </ListItem>
            <ListItem dense>
                <ListItemAvatar><CloudDownloadIcon/></ListItemAvatar>
                <ListItemText
                    primaryTypographyProps={{style: {fontWeight: "bold"}}}
                    primary={"Download a 3D model"}
                    secondary={"You can do it on the website or by the link we sent to your email"}
                />
            </ListItem>
        </List>
    );
});

export default UserGuideChecklist;
