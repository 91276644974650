import React from "react";

export type StorageItemsType = { [key: string]: string; };

export interface ILocalStorage {
    setItem: (key: string, value: string) => void,
    removeItem: (key: string) => void;
    items: StorageItemsType;
    localStorageLoaded: boolean;
}


const LocalStorageContext = React.createContext<ILocalStorage>({
    setItem: () => {
    },
    removeItem: () => {
    },
    items: {},
    localStorageLoaded: false,
});

export interface LocalStorageProviderProps {
    children?: any;
}

export const LocalStorageProvider = React.forwardRef((props: LocalStorageProviderProps, ref: React.Ref<any>) => {
    const {
        children
    } = props;

    const [storageItems, setStorageItems] = React.useState<StorageItemsType>({});
    const [storageLoaded, setStorageLoaded] = React.useState<boolean>(false);

    const setItem = (key: string, value: string) => {
        const oldValue = window.localStorage.getItem(key);
        localStorage.setItem(key, value);
        const event = new StorageEvent("storage", {
            key: key,
            oldValue: oldValue,
            newValue: value,
            storageArea: localStorage,
        });
        window.dispatchEvent(event);
    }

    const removeItem = (key: string) => {
        const oldValue = window.localStorage.getItem(key);
        localStorage.removeItem(key);
        const event = new StorageEvent("storage", {
            key: key,
            oldValue: oldValue,
            newValue: null,
            storageArea: localStorage,
        });
        window.dispatchEvent(event);
    }

    const fetchStorage = (storage: Storage): StorageItemsType => {
        const newVal: StorageItemsType = {};
        for (let i = 0; i < storage.length; ++i) {
            const key: string = storage.key(i) as string;
            const value: string | null = storage.getItem(key);
            if (value) newVal[key] = value;
        }
        return newVal;
    }

    React.useEffect(() => {
        setStorageItems(fetchStorage(localStorage));

        const eventHandler = (event: StorageEvent) => {
            setStorageItems(fetchStorage(event.storageArea || localStorage));
        };

        window.addEventListener("storage", eventHandler);
        setStorageLoaded(true);
        return () => {
            window.removeEventListener("storage", eventHandler);
        }
    }, [])

    if (!storageLoaded) return null;

    return (
        <LocalStorageContext.Provider
            value={{
                setItem,
                removeItem,
                items: storageItems,
                localStorageLoaded: storageLoaded
            }}
        >
            {children}
        </LocalStorageContext.Provider>
    );
});

const useLocalStorage = (): ILocalStorage => React.useContext(LocalStorageContext);
export default useLocalStorage;