import React from "react";
import generateStyles from "./styles";
import {
    CircularProgress,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Tooltip,
    useTheme
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import VerifiedIcon from '@mui/icons-material/Verified';
import ErrorIcon from '@mui/icons-material/Error';
import JobItem from "../../interfaces/JobItem";
import {coreRequest} from "../../core/coreRequest";

export interface TaskProgressProps {
    job: JobItem;
}

const TaskProgress = React.forwardRef((props: TaskProgressProps, ref) => {
    const {
        job
    } = props;

    const theme = useTheme();
    const styles = generateStyles(theme, props);

    const handleDownloadClick = () => {
        coreRequest
            .post("/analytics")
            .set("Content-Type", "application/json")
            .send({id: job.uid})
            .then(() => {});
        const res = window.open(job.url, "_blank");
        if (res) res.focus();
    }

    let icon = null;
    if (job.error) {
        icon = <ErrorIcon color="error" fontSize="large"/>
    } else if (job.url) {
        icon = <VerifiedIcon color="primary" fontSize={"large"}/>
    } else {
        icon = <CircularProgress color="primary"/>
    }

    return (
        <ListItem>
            <ListItemAvatar>
                {icon}
            </ListItemAvatar>
            <ListItemText
                primary={job.filename}
                primaryTypographyProps={{style: {color: job.error ? theme.palette.error.main : undefined}}}
                secondaryTypographyProps={{style: {color: job.error ? theme.palette.error.main : undefined}}}
                secondary={job.error || job.email}
            />
            <ListItemSecondaryAction>
                <Tooltip title="Download 3d model">
                    <IconButton
                        disabled={!job.url || !!job.error}
                        onClick={handleDownloadClick}
                    >
                        <SaveIcon/>
                    </IconButton>
                </Tooltip>
            </ListItemSecondaryAction>
        </ListItem>
    );
});

export default TaskProgress;
