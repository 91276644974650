import {Theme} from "@mui/material";
import { jsx, css } from "@emotion/react"
import {LinearProgressWithLabelProps} from "./LinearProgressWithLabel";

const styles = (theme: Theme, props: LinearProgressWithLabelProps) => ({
    header: css`
      background-color: ${theme.palette.primary.main};
      margin-bottom: 1.45rem;
    `,
    link: css`
      color: ${theme.palette.primary.contrastText};
      text-decoration: none;
    `,
    title: css`
      margin: 0 auto;
      padding: 1.45rem 1.0875rem;
    `

})
export default styles;
