import JobItem from "../interfaces/JobItem";

export default function encodeJobsCache(jobs: JobItem[]): string {
    return JSON.stringify(jobs.map(item => ({
        email: item.email,
        filename: item.filename,
        url: item.url,
        uid: item.uid,
        error: item.error,
    })));
}
