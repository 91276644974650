import React from 'react';
import './App.css';
import {CssBaseline} from "@mui/material";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {LocalStorageProvider} from "./hooks/useLocalStorage";
import MainPage from "./MainPage";
import {AuthProvider} from "./hooks/useAuth";

const theme = createTheme({
    palette: {
        mode: 'dark',
    },
    typography: {
        fontFamily: "'Open Sans', sans-serif;"
    }
});
// 'Syne Mono', monospace;
function App() {
    return (
        <LocalStorageProvider>
            <AuthProvider>
                <ThemeProvider theme={theme}>
                    <CssBaseline/>
                    <MainPage/>
                </ThemeProvider>
            </AuthProvider>
        </LocalStorageProvider>
    );
}

export default App;
