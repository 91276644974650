/** @jsxImportSource @emotion/react */
import {
    Box,
    Chip,
    Container,
    Grid,
    List,
    ListItem,
    Tab,
    Tabs,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import UploadForm from "./components/UploadForm";
import React from "react";
import useLocalStorage from "./hooks/useLocalStorage";
import JobItem from "./interfaces/JobItem";
import UserGuideChecklist from "./components/UserGuideChecklist";
import JobsList from "./components/JobsList";
import decodeJobsCache from "./core/decodeJobsCache";
import {coreRequest} from "./core/coreRequest";
import encodeJobsCache from "./core/encodeJobsCache";
import {css} from "@emotion/react";

const MainPage = () => {
    const theme = useTheme();
    const lStorage = useLocalStorage();
    const [jobs, setJobs] = React.useState<JobItem[]>([]);
    const [activeTab, setActiveTab] = React.useState("guide");
    const pollingInterval = React.useRef<any>();

    React.useEffect(() => {
        try {
            if (lStorage.items.videoProcessingJobs) {
                try {
                    setJobs(decodeJobsCache(lStorage.items.videoProcessingJobs));
                } catch (error) {
                    if (Array.isArray(error)) {
                        for (const err of error) {
                            console.error(err);
                        }
                    } else {
                        console.error(error);
                    }
                }
            }
        } catch (error) {
            console.error("Failed to decode json from local storage. Clearing cache...");
            lStorage.removeItem("videoProcessingJobs");
        }
    }, [lStorage, lStorage.items]);

    const pollingCallback = () => {
        if (jobs.length) {
            coreRequest
                .post("/downloadLink")
                .set("Content-Type", "application/json")
                .send({ids: jobs.map(item => item.uid)})
                .then((response) => {
                    if (response.body && typeof(response.body) == "object") {
                        lStorage.setItem("videoProcessingJobs", encodeJobsCache(jobs.map((job) => {
                            const newJob: JobItem = {...job};
                            const record: any = response.body[newJob.uid];
                            if (record) {
                                console.error("Polling status: ", record.status, " record: ", record);
                                switch (record.status) {
                                    case "Success":
                                        newJob.url = record.link;
                                        newJob.error = undefined;
                                        break;
                                    case "Error":
                                        newJob.error = record.error;
                                        newJob.url = undefined;
                                        break;
                                    case "Processing":
                                        break;
                                }
                            }
                            return newJob;
                        })));
                    }
                })
                .catch(error => {

                });
        }
    };

    React.useEffect(() => {
        pollingInterval.current = setInterval(pollingCallback, 60 * 1000);
        return () => {
            clearInterval(pollingInterval.current);
        }
    }, [jobs]);

    React.useEffect(() => {
        pollingCallback();
    }, []);

    const handleClearAllJobs = () => {
        lStorage.setItem("videoProcessingJobs", JSON.stringify([]));
    }

    const handleClearFinishedJobs = () => {
        lStorage.setItem("videoProcessingJobs", JSON.stringify(jobs.filter(item => !item.url)));
    }

    const handleTabSwitch = (event: React.SyntheticEvent, newValue: string) => {
        setActiveTab(newValue);
    }

    return (
        <Grid container style={{minHeight: "100vh"}}>
            <Grid item xs={12} md={6} lg={8} xl={9}
                  css={css`
                    height: auto;
                    overflow: hidden;
                    min-height: 50vh;
                    ${theme.breakpoints.up("md")} {
                      height: 100vh;
                      min-height: auto;
                    }
                  `}
            >
                <iframe style={{width: "100%", height: "100%", padding: 0, margin: 0, border: 0}}
                        src="https://www.youtube.com/embed/7Zfky2DCHKg"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                />
            </Grid>
            <Grid item xs={12} md={6} lg={4} xl={3}
                  css={css`
                    padding: 0;
                    margin: 0;
                    height: auto;
                    ${theme.breakpoints.up("md")} {
                        overflow-x: scroll;
                        height: 100vh;
                     }
                  `}
            >
                <Container>
                    <Box paddingTop={theme.spacing(4)}>
                        <Typography variant="h4" align="center" className="zibraFont">
                            Video to 3D <Chip label={"Demo"} style={{fontSize: 20}} className="zibraFont"/>
                        </Typography>
                        <Typography variant="h6" align="center">
                            Get a 3D model of any object around you!
                        </Typography>
                        <Tabs
                            style={{paddingTop: theme.spacing(4)}}
                            onChange={handleTabSwitch}
                            value={activeTab}
                            variant="fullWidth"
                        >
                            <Tab label="User Guide" className="zibraFont" value={"guide"}/>
                            <Tab label="Processing" className="zibraFont" value={"jobs"}/>
                        </Tabs>
                        <Box paddingTop={theme.spacing(4)}>
                            {activeTab === "jobs" &&
                                <JobsList jobs={jobs} onClearAll={handleClearAllJobs}
                                          onClearFinished={handleClearFinishedJobs}/>
                            }
                            {activeTab === "guide" && <UserGuideChecklist/>}
                        </Box>
                        <List>
                            <ListItem style={{paddingTop: theme.spacing(8)}}>
                                <UploadForm setActiveTab={setActiveTab}/>
                            </ListItem>
                        </List>
                    </Box>
                </Container>
            </Grid>
        </Grid>
    );
}

export default MainPage;
