import {Theme} from "@mui/material";
import { jsx, css } from "@emotion/react"
import {UploadVideoDialogProps} from "./UploadVideoDialog";

const styles = (theme: Theme, props: UploadVideoDialogProps) => ({
    testclass: {
        cursor: "copy",
        height: "60vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "repeating-linear-gradient(45deg, rgba(1,1,1,0.1), rgba(1,1,1,0.1) 10px, rgba(30,30,30,0.1) 10px, rgba(30,30,30,0.1) 20px)",
        padding: theme.spacing(4),
    }
})
export default styles;
