import React, {ForwardedRef} from "react";
import {
    ClickAwayListener,
    Divider,
    Grow,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    MenuItem,
    MenuList,
    Paper,
    Popper, Typography,
    useTheme
} from "@mui/material";
import JobItem from "../../interfaces/JobItem";
import TaskProgress from "../TaskProgress";
import LayersClearIcon from '@mui/icons-material/LayersClear';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

interface JobsListProps {
    jobs: JobItem[];
    onClearFinished: () => void;
    onClearAll: () => void;
}

const JobsList = React.forwardRef((props: JobsListProps, ref: ForwardedRef<any>) => {
    const {
        jobs,
        onClearFinished,
        onClearAll
    } = props;
    const theme = useTheme();
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const [menuMorePopperOpen, setMenuMorePopperOpen] = React.useState<boolean>(false);

    const handleOpen = () => {
        setMenuMorePopperOpen(true);
    }

    const handleClose = () => {
        setMenuMorePopperOpen(false);
    };

    const handleOnClearFinished = () => {
        handleClose();
        onClearFinished();
    }

    const handleOnClearAll = () => {
        handleClose();
        onClearAll();
    }

    return (
        <React.Fragment>

        <List ref={ref}>
            <ListItem>
                <ListItemText primaryTypographyProps={{variant: "h6"}}>
                    3D models status
                </ListItemText>
                <Popper
                    open={menuMorePopperOpen}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    placement="bottom-start"
                    transition
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList
                                        autoFocusItem={menuMorePopperOpen}
                                        id="composition-menu"
                                        aria-labelledby="composition-button"
                                    >
                                        <MenuItem onClick={handleOnClearAll}>
                                            <ListItemIcon>
                                                <HighlightOffIcon/>
                                            </ListItemIcon>
                                            <ListItemText>Clear all</ListItemText>
                                        </MenuItem>
                                        <MenuItem onClick={handleOnClearFinished}>
                                            <ListItemIcon>
                                                <LayersClearIcon/>
                                            </ListItemIcon>
                                            <ListItemText>Clear finished</ListItemText>
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
                <ListItemSecondaryAction>
                    <IconButton onClick={handleOpen} ref={anchorRef}>
                        <MoreVertIcon/>
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            <Divider />
            {jobs.map((item: JobItem) => <TaskProgress key={item.uid} job={item}/>)}
        </List>
        <Paper>
            <List>
                <ListItem>
                    <ListItemText
                        primary={"Building 3D models."}
                        secondary={"This may take a while. You will be able to download models as soon as they are ready. We will also send a download links to your email."}
                    />
                </ListItem>
            </List>
        </Paper>
        </React.Fragment>

    );
});

export default JobsList;
