import React from "react";
import useLocalStorage from "./useLocalStorage";

export interface IAuthVars {
    email?: string,
    authLoaded: boolean;
}

export interface IAuth extends IAuthVars {
    setEmail: (email?: string) => void;
}


const AuthContext = React.createContext<IAuth>({
    setEmail: () => {
    },
    authLoaded: false,
});

export interface AuthProps {
    children?: any;
}

export const AuthProvider = React.forwardRef((props: AuthProps, ref: React.Ref<any>) => {
    const {
        children
    } = props;
    const lStorage = useLocalStorage();
    const [authVars, setAuthVars] = React.useState<IAuthVars>({authLoaded: false});

    React.useEffect(() => {
        if (lStorage.items.email) {
            setAuthVars(prev => ({...prev, email: lStorage.items.email}));
        }
        setAuthVars(prev => ({...prev, authLoaded: true}));
    }, [lStorage.items.email]);

    const setEmail = (email?: string) => {
        if (email) {
            lStorage.setItem("email", email);
        } else {
            lStorage.removeItem("email");
        }
    }

    return (
        <AuthContext.Provider value={{...authVars, setEmail}}>
            {children}
        </AuthContext.Provider>
    );
});

const useAuth = (): IAuth => React.useContext(AuthContext);
export default useAuth;