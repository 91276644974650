import React from "react";
import generateStyles from "./styles";
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormGroup, FormLabel, Link,
    TextField, Typography,
    useTheme
} from "@mui/material";
import UploadVideoDialog from "../UploadVideoDialog";
import useAuth from "../../hooks/useAuth";
import useLocalStorage from "../../hooks/useLocalStorage";
import UploadLimitsData from "../../interfaces/UploadLimitsData";
import decodeUploadLimitsData from "../../core/decodeUploadLimitsData";
import encodeUploadLimitsData from "../../core/encodeUploadLimitsData";

const UPLOAD_LIMIT: number = 5;

export interface UploadFormProps {
    setActiveTab: (tab: string) => void;
}


interface FormDataWithValidation {
    emailError?: string,
}

const getYesterday = () => new Date(Date.now() - 86400000);

const UploadForm = React.forwardRef((props: UploadFormProps, ref) => {
    const {setActiveTab} = props;
    const theme = useTheme();
    const styles = generateStyles(theme, props);
    const {email, setEmail, authLoaded} = useAuth();
    const lStorage = useLocalStorage();

    const [uploadDialogOpen, setUploadDialogOpen] = React.useState<boolean>(false);
    const [termsAgreed, setTermsAgreed] = React.useState<boolean>(false);
    const [formData, setFormData] = React.useState<FormDataWithValidation>({});

    const onUploadVideoButtonClick = () => {
        setUploadDialogOpen(true);
    }

    const handleSuccessfulLoad = () => {
        setActiveTab("jobs");
        if (lStorage.items.uploadLimit) {
            const uploadLimit: UploadLimitsData = decodeUploadLimitsData(lStorage.items.uploadLimit);
            if (uploadLimit.date < getYesterday()) {
                lStorage.setItem("uploadLimit", encodeUploadLimitsData({date: new Date(), uploads: 1}));
            } else {
                lStorage.setItem("uploadLimit", encodeUploadLimitsData({
                    date: uploadLimit.date,
                    uploads: uploadLimit.uploads + 1
                }));
            }
        } else {
            lStorage.setItem("uploadLimit", encodeUploadLimitsData({date: new Date(), uploads: 1}));
        }
    }

    React.useEffect(() => {
        if (email) {
            const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
            if (!emailRegex.test(email))
                setFormData(prev => ({...prev, emailError: "Incorrect email"}))
            else {
                setFormData(prev => ({...prev, emailError: undefined}))
            }
        }
    }, [email]);

    if (!authLoaded) {
        return (
            <Box style={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%"}}>
                <CircularProgress size={100}/>
            </Box>
        );
    }

    let uploadLimitReached: boolean = false;
    if (lStorage.items.uploadLimit) {
        const uploadLimit: UploadLimitsData = decodeUploadLimitsData(lStorage.items.uploadLimit);
        if (uploadLimit.date < getYesterday()) {
            lStorage.removeItem("uploadLimit");
        } else if (uploadLimit.uploads >= UPLOAD_LIMIT) {
            uploadLimitReached = true;
        }
    }

    return (
        <React.Fragment>
            <UploadVideoDialog
                isOpen={uploadDialogOpen}
                setIsOpen={setUploadDialogOpen}
                onSuccessfullyLoad={handleSuccessfulLoad}
            />
            <FormControl fullWidth>
                <FormGroup>
                    <TextField
                        error={!!formData.emailError}
                        helperText={formData.emailError || undefined}
                        id="email-input"
                        label="e-mail"
                        variant="outlined"
                        placeholder="example@mail"
                        fullWidth
                        inputMode={"email"}
                        defaultValue={email}
                        onBlur={(event) => {
                            setEmail(event.target?.value || undefined);
                        }}
                    />
                </FormGroup>
                <FormGroup>
                    <FormControlLabel
                        style={{paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1)}}
                        control={<Checkbox onClick={() => setTermsAgreed(prev => !prev)} checked={termsAgreed}/>}
                        label={
                            <Typography variant={"subtitle2"}>
                                I have read and agreed to the&nbsp;
                                <Link href={"https://zibra.ai/end-user-license-agreement/"} target="_blank">
                                    End User License Agreement
                                </Link>
                                &nbsp;and the&nbsp;
                                <Link href={"https://zibra.ai/privacy-policy/"} target="_blank">
                                    Privacy Policy
                                </Link>
                            </Typography>
                        }
                    />
                </FormGroup>
                <FormGroup>
                    <Button
                        variant="outlined"
                        onClick={onUploadVideoButtonClick}
                        fullWidth
                        disabled={!email || !termsAgreed || !!formData.emailError || uploadLimitReached}
                        className="zibraFont"
                    >
                        Upload video
                    </Button>
                    {uploadLimitReached &&
                        <FormLabel error>You've reached max uploads per day. Try again later</FormLabel>
                    }
                </FormGroup>
            </FormControl>
        </React.Fragment>
    );
});

export default UploadForm;
