import JobItem from "../interfaces/JobItem";

export default function decodeJobsCache(cache: string): JobItem[] {
    const result: JobItem[] = [];

    const decoded: any = JSON.parse(cache);
    if (!Array.isArray(decoded)) {
        throw SyntaxError("Wrong format of jobs cache. Clearing cache...");
    }

    const errors: SyntaxError[] = [];
    for (const jobItem of decoded as any[]) {
        if (!jobItem.email || !jobItem.filename || !jobItem.uid) {
            errors.push(SyntaxError(`Invalid cache item. Deleting entry. ${jobItem}`));
            continue;
        }
        result.push({
            email: `${jobItem.email}`,
            filename: `${jobItem.filename}`,
            url: jobItem.url,
            uid: `${jobItem.uid}`,
            error: jobItem.error,
        });
    }
    if (errors.length)
        throw errors;
    
    return result;
}